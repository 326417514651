<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable :fields="fields" :items="doctors" :actions="actions" title="Doctores"
          :labelItemPerPage="'Doctores por página:'"
          :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir nuevo doctor', event: 'addElement' }"
          @addElement="addDoctor" @seeInfo="handleSeeInfo" @showDeleteModal="handleModal"
          :noResults="'No se han encontrado doctores'" />
        <DeleteModal :modalContent="modalContent" @delete="deleteDoctorData(doctorId)" :showModal="deleteModal"
          @closeModal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>

<script>

import { generateField } from '@/utils/utils';
import { decodePayload } from "@/utils/token";
import { getDoctorsData, deleteDoctor } from "@/services/doctors";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from '@/components/Loader.vue';
export default {
  name: "doctorTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      doctors: [],
      doctor: {},
      doctorId: 0,
      doctorName: '',
      doctorSurName: '',
      loading: true,
      deleteModal: false,
      GENDER_MEN: "Hombre",
      GENDER_WOMAN: "Mujer",
      GENDER_OTHER: "Otros",
      fields: [
        generateField("name", "Nombre", {styleData: { textAlign: 'center'}}),
        generateField("surname", "Apellidos", {styleData: { textAlign: 'center'}}),
        generateField("email", "Email", {styleData: { textAlign: 'center'}}),
        generateField("gender", "Género", {styleData: { textAlign: 'center'}}),
        generateField("actions", 'Acciones', { show: this.$store.state.isAdmin, sorter: false, filter: false })
      ],
      actions: [
        {
          key: 'information',
          label: 'Información',
          event: 'seeInfo',
          classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
        },
        {
          key: 'delete',
          label: 'Eliminar',
          event: 'showDeleteModal',
          classButton: 'btn btn-delete btn-sm btn-pill'
        },
      ],
      modalContent: {
        title: 'Eliminar doctor',
        textBody: '',
        buttons: [
          {
            textButton: 'Cerrar',
            color: 'secondary',
            event: 'closeModal'
          },
          {
            textButton: 'Aceptar',
            color: 'danger',
            event: 'delete'
          },
        ]
      }
    }
  },
  created() {
    let user = decodePayload(localStorage.token);
    if (user.type === "doctor") {
      localStorage.removeItem("token");
      this.$router.push("/login")
    }
    this.getDoctors();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla.
     */
    getDoctors() {
      getDoctorsData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
          this.doctors = result
          this.doctors.forEach((doctor) => {
            if (doctor.gender == 'male') {
              doctor.gender = this.GENDER_MEN;
            } else if (doctor.gender == 'female') {
              doctor.gender = this.GENDER_WOMAN;
            } else {
              doctor.gender = this.GENDER_OTHER;
            }
            doctor.weight = doctor.weight + ' kg';
            if (doctor.height > 100) {
              doctor.height = doctor.height / 100 + ' m';
            } else {
              doctor.height = doctor.height + ' m';
            }
          })
          this.loading = false;
        });
    },
    /**
     * Este método se ejecuta cuando el usuario pulsa Añadir nuevo doctor y redirige a la vista con el formulario para poder crear uno.
    */
    addDoctor() {
      this.$router.push(`/doctor/create/`);
    },
    /**
     * Este método se ejecuta cuando se pulsa Información, para poder ver información del doctor
    */
    handleSeeInfo(item) {
      this.$router.push(`/doctor/info/${item.id}`);
    },
    /**
    * Método para controlar lo que se muestra en el Modal de Eliminar doctor.
    */
    handleModal(item) {
      this.doctorId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar el doctor: <strong>"${item.name} ${item.surname}</strong>"? Esta acción no se puede deshacer.`,
        this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el doctor, mandando por la URL el id
     * 
     * @param {number} id - El id del doctor a eliminar
     */
    deleteDoctorData(id) {
      deleteDoctor(id).then(() => {
        this.getDoctors()
      })
      this.deleteModal = false;
    },
    /**
     * Función para cerrar el Modal.
     */
    closeModal() {
      this.deleteModal = false;
    }
  }
};
</script>